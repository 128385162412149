import React from 'react';
import { graphql } from 'gatsby';
import Layout, { SiteContext } from '../Layout';
import StoryContentLoop from '../StoryContentLoop';
import Dochead from '../Dochead';
import imageUrlFor, { buildImageObj } from '../../js/imageUrlFor';
import StoryBanner from '../heroBanners/StoryBanner';
import StoryDonation from '../StoryDonation';

const Story = ({
  data: {
    story: {
      title,
      donationMessage,
      slug: { current },
      _rawMainImage,
      bannerHeading: heading,
      bannerMessage: message,
      _rawFlexibleContent,
      _rawDonationlink,
      _rawAdditionallink,
    },
  },
}) => (
  <Layout>
    <SiteContext.Consumer>
      {(value) => (
        <>
          <Dochead
            title={`${title}`}
            description={donationMessage || value.siteDescription}
            siteName={value.siteTitle}
            pageUrl={`/${current}`}
            pageImage={
              _rawMainImage.asset
                ? imageUrlFor(buildImageObj(_rawMainImage))
                    .width(600)
                    .quality(100)
                    .url()
                : imageUrlFor(buildImageObj(value.siteImage))
                    .width(600)
                    .quality(100)
                    .url()
            }
          />
          <StoryBanner copy={{ heading, message }} image={_rawMainImage} />
          <StoryContentLoop contentArray={_rawFlexibleContent} />

          <StoryDonation
            heading={donationMessage}
            mainButton={_rawDonationlink}
            secondaryButton={_rawAdditionallink}
          />
        </>
      )}
    </SiteContext.Consumer>
  </Layout>
);

export default Story;

export const query = graphql`
  query StoryQuery($id: String!) {
    story: sanityCampaign(_id: { eq: $id }) {
      title
      slug {
        current
      }
      bannerHeading
      bannerMessage
      _rawMainImage(resolveReferences: { maxDepth: 10 })
      _rawFlexibleContent(resolveReferences: { maxDepth: 10 })
      donationMessage
      _rawDonationlink
      _rawAdditionallink
    }
  }
`;
